/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlTree } from '@angular/router';
import { CrmRoleDto } from 'apps-dto/dist/services/crm/user/dtos/crm-role.dto';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

const BASE_ROLE_ROUTES: Record<string, string[]> = {
  technic: ['/technic'],
};

@Injectable({
  providedIn: 'root',
})
export class RolesGuard implements CanActivate, CanLoad {
  constructor(private readonly router: Router, private readonly authService: AuthService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const roles: (CrmRoleDto | string)[] | null = route.data?.['roles'] || null;
    const notRoles: (CrmRoleDto | string)[] | null = route.data?.['notRoles'] || null;

    return this.checkRoles(roles || [], notRoles || []);
  }

  public canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const roles: (CrmRoleDto | string)[] | null = route.data?.['roles'] || null;
    const notRoles: (CrmRoleDto | string)[] | null = route.data?.['notRoles'] || null;

    return this.checkRoles(roles || [], notRoles || []);
  }

  protected checkRoles(roles: (CrmRoleDto | string)[], notRoles: (CrmRoleDto | string)[]): Observable<boolean> {
    return this.authService.profile$.pipe(
      filter(Boolean),
      map((profile) => {
        const rolesApproved = !roles?.length || roles.includes(profile.role.code);
        const notRolesApproved = !notRoles?.length || !notRoles.includes(profile.role.code);

        if (rolesApproved && notRolesApproved) {
          return true;
        } else {
          const baseRoute = BASE_ROLE_ROUTES[profile.role.code as unknown as string] || ['/'];
          this.router.navigate(baseRoute);
          return false;
        }
      }),
    );
  }
}
