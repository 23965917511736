import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmVehicleGetResponseDto, CrmVehicleRemoveDeviceRequestDto } from './dtos';

/**
 * ### Извлечение устройства из автомобиля
 * basePath: /crm
 * controller: /vehicle
 * path: '/:vin/device'
 * method: DELETE
 * fullPath: /crm/vehicle/:vin/device
 */

export namespace CrmVehicleRemoveDeviceEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Vehicle;
  export const endPointPath = ':vin/device';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.DELETE;

  /**
   * Ответ: Машина
   */
  export type ResponseData = CrmVehicleGetResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export interface RequestPathParams extends Record<string, string | number> {
    vin: string;
  }

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmVehicleRemoveDeviceRequestDto;
}
