import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { RentModelLite } from './models';

/**
 * ### Получение истории аренд с пагинацией
 * basePath: /mobile-bff
 * controller: /rent
 * path: 'history'
 * method: GET
 * fullPath: /mobile-bff/rent/history
 */
export namespace MobileBffGetRentHistoryEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Rent;
  export const endPointPath = 'history';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: не законченная аренда
   */
  export type ResponseData = PaginationInterface<RentModelLite>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = {
    page: number;
    onPage: number;
    sortField?: string;
    sortDirection?: 'asc' | 'desc';
  };

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
