export interface CrmVehicleGetMonitoringResponseDto {
  page: number;
  onPage: number;
  rows: CrmVehicleTripInfoHourly[];
  total: number;
  /** Общая статистика по поездкам за выбранный промежуток времени */
  statistics: CrmMonitoringHourlyTotalStats;
}

export interface CrmMonitoringHourlyTotalStats {
  avgSpeed: number;
  maxSpeed: number;
  totalFuel: number;
  totalLengthCity: number;
  totalLengthCountry: number;
  totalTime: number;
}

export interface CrmVehicleTripInfoHourly {
  /** url карты с отображением поездки */
  mapImage: string | null;
  /** Время в ISO начала движения */
  timeStart: Date;
  /** Время в ISO остановки */
  timeStop: Date;
  /** Адрес откуда началось движение */
  addressStartLocation: string | null;
  /** Адрес где закончилось движение */
  addressStopLocation: string | null;
  /** Статистика */
  stats: CrmVehicleTripInfoHourlyStats;
  /** Стиль вождения */
  drivingStyle: number;
  geojson: CrmTrack;
}

export interface CrmVehicleTripInfoHourlyStats {
  /** Средняя скорость за выбранный период, км/ч */
  avgSpeed: number;
  /** Максимальная скорость за выбранный период, км/ч */
  maxSpeed: number;
  /** Потраченное топливо скорость за выбранный период, л */
  totalFuel: number;
  /** Пробег в городе за выбранный период, км */
  totalLengthCity: number;
  /** Пробег вне города за выбранный период, км */
  totalLengthCountry: number;
  /** Время поездок за выбранный период, сек. */
  totalTime: number;
}

export interface CrmTrack {
  type: 'FeatureCollection';
  features: (CrmGeoJsonTrack | CrmGeoJsonEvent)[];
}

export enum ECrmFeatureType {
  Track = 'Track',
  Event = 'Event',
}

export enum ECrmEventType {
  Start = 'Start',
  SharpAcceleration = 'SharpAcceleration',
  SharpBraking = 'SharpBraking',
  SharpTurnLeft = 'SharpTurnLeft',
  SharpTurnRight = 'SharpTurnRight',
  OffRoad = 'OffRoad',
  Stop = 'Stop',
}

export interface CrmGeoJsonTrack {
  type: 'Feature';
  properties: {
    type: ECrmFeatureType.Track;
  };
  geometry: {
    type: 'LineString';
    /** [[lon, lat],[lon, lat]] */
    coordinates: number[][];
  };
}

export interface CrmGeoJsonEvent {
  type: 'Feature';
  properties: {
    type: ECrmFeatureType.Event;
    data: {
      /** Тип события */
      type: ECrmEventType[];
      /** Дата в ISO */
      date: Date;
    };
  };
  geometry: {
    /** GeoJson Type */
    type: 'Point';
    /** Координаты [lon, lat] */
    coordinates: number[];
  };
}
