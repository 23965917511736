import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiBaseUrlInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.changeRequest(req));
  }

  private changeRequest(req: HttpRequest<unknown>): HttpRequest<unknown> {
    const url =
      req.url.startsWith('http') || req.url.startsWith('/assets')
        ? req.url
        : this.joinUrl(environment.baseUrl as string, req.url);

    return req.clone({
      url,
    });
  }

  private joinUrl(...segments: string[]): string {
    return segments
      .filter((p) => !!p)
      .map((p, i) => (i > 0 ? this.strip(p) : this.stripRight(p)))
      .join('/');
  }

  private strip(path: string): string {
    return this.stripLeft(this.stripRight(path));
  }

  private stripRight(path: string): string {
    return path.replace(new RegExp(/\/$/), '');
  }

  private stripLeft(path: string): string {
    return path.replace(new RegExp(/^\//), '');
  }
}
