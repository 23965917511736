import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/auth/guards/auth.guard';
import { RolesGuard } from '@app/core/auth/guards/roles.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('@app/pages-core/login-page/login-page.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'technic',
    loadChildren: () =>
      import('@app/pages-core/technic-container-page/technic-container-page.module').then(
        (m) => m.TechnicContainerPageModule,
      ),
    canActivate: [AuthGuard, RolesGuard],
    data: {
      roles: ['technic'],
    },
  },
  {
    path: 'public',
    loadChildren: () => import('@app/pages-core/public-pages/public-pages.module').then((m) => m.PublicPagesModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('@app/pages-core/main-container-page/main-container-page.module').then((m) => m.MainContainerPageModule),
    canActivate: [AuthGuard, RolesGuard],
    data: {
      notRoles: ['technic'],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
