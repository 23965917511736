import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmUserDto } from '../user/dtos';

/**
 * ### Получение текущего пользователя CRM
 * basePath: /crm
 * controller: /auth
 * path: '/me'
 * method: GET
 * fullPath: /crm/auth/login
 */
export namespace CrmMeGetEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Auth;
  export const endPointPath = 'me';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Пользователь
   */
  export type ResponseData = CrmUserDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};
}
