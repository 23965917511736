import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { MobileBffSendToKycRequestDto } from './dtos';

/**
 * ### Отправка данных в KYC
 * basePath: /mobile-bff
 * controller: /customer
 * path: '/send-to-kyc'
 * method: POST
 * fullPath: /mobile-bff/customer/send-to-kyc
 */
export namespace MobileBffSendToKycEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Customer;
  export const endPointPath = 'send-to-kyc';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: отсутствует
   */
  export type ResponseData = {};
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: фотографии пользователя  */
  export type RequestBody = MobileBffSendToKycRequestDto;

  export enum ErrorCodes {}
}
