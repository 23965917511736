import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsDtoRequest } from '@app/shared/utils/apps-dto-request.class';
import { CrmLoginEndpoint, CrmMeGetEndpoint } from 'apps-dto';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private readonly http: HttpClient) {}

  public login(request: CrmLoginEndpoint.RequestBody): Observable<CrmLoginEndpoint.ResponseData> {
    const appDtoRequest = new AppsDtoRequest<
      CrmLoginEndpoint.RequestBody,
      CrmLoginEndpoint.RequestPathParams,
      CrmLoginEndpoint.RequestQueryParams,
      CrmLoginEndpoint.RequestHeaders,
      CrmLoginEndpoint.Response
    >(this.http, {
      type: CrmLoginEndpoint.type,
      getUrl: CrmLoginEndpoint.getUrl,
    });

    return appDtoRequest
      .execute({
        query: {},
        headers: {},
        path: {},
        body: request,
      })
      .pipe(map((response) => response.data));
  }

  public getMe(token?: string | null): Observable<CrmMeGetEndpoint.ResponseData> {
    const appDtoRequest = new AppsDtoRequest<
      CrmMeGetEndpoint.RequestBody,
      CrmMeGetEndpoint.RequestPathParams,
      CrmMeGetEndpoint.RequestQueryParams,
      CrmMeGetEndpoint.RequestHeaders,
      CrmMeGetEndpoint.Response
    >(this.http, {
      type: CrmMeGetEndpoint.type,
      getUrl: CrmMeGetEndpoint.getUrl,
    });

    const headers: CrmMeGetEndpoint.RequestHeaders = {};

    if (token) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      headers['Authorization'] = `Bearer ${token}`;
    }

    return appDtoRequest
      .execute({
        query: {},
        headers,
        path: {},
        body: {},
      })
      .pipe(map((response) => response.data));
  }
}
