import { CrmGeoJsonEvent, CrmTrack } from './crm-vehicle-get-monitoring.response.dto';

export interface CrmVehicleGetMonitoringDailyResponseDto {
  totalStats: CrmVehicleTripDailyInfoStats;
  /** Поездки */
  rows: CrmVehicleTripDayInfo[];
  /** Пагинация */
  total: number;
  page: number;
  onPage: number;
}

export enum ECrmGeoJsonType {
  Trip = 'Trip',
  Event = 'Event',
}

export interface CrmVehicleTripDayInfo {
  /** Дата начала дня */
  time: Date;
  /** Треки за день */
  tracks: (CrmVehicleTripDailyInfo | CrmVehicleEventDailyInfo)[];
  /** Время поездок за день */
  driveTime: number;
  /** Средняя скорость за день */
  avgSpeed: number;
  /** Максимальная скорость за день */
  maxSpeed: number;
  /** Потраченное топливо скорость за день */
  fuel: number;
  /** Пробег в городе за день */
  cityMileage: number;
  /** Пробег вне города за день */
  countryMileage: number;
  /** Стиль вождения на конец дня */
  drivingStyle: number;
}

export interface CrmVehicleEventDailyInfo {
  type: ECrmGeoJsonType.Event;
  geojson: CrmGeoJsonEvent;
}

export interface CrmVehicleTripDailyInfo {
  type: ECrmGeoJsonType.Trip;
  /** url карты с отображением поездки */
  mapImage: string | null;
  /** Время в ISO начала движения */
  timeStart: Date;
  /** Время в ISO остановки */
  timeStop: Date;
  /** Адрес откуда началось движение */
  addressStartLocation: string | null;
  /** Адрес где закончилось движение */
  addressStopLocation: string | null;
  /** Статистика */
  stats: CrmVehicleTripDailyInfoStats;
  /** Стиль вождения */
  drivingStyle: number;
  geojson: CrmTrack;
  /** Эвакуация */
  towing: boolean;
}

export interface CrmVehicleTripDailyInfoStats {
  /** Средняя скорость за выбранный период, км/ч */
  avgSpeed: number;
  /** Максимальная скорость за выбранный период, км/ч */
  maxSpeed: number;
  /** Потраченное топливо скорость за выбранный период, л */
  fuel: number;
  /** Пробег в городе за выбранный период, км */
  mileageCity: number;
  /** Пробег вне города за выбранный период, км */
  mileageTrack: number;
  /** Пробег за выбранный период, км */
  mileage: number;
  /** Время поездок за выбранный период, сек. */
  time: number;
}
