import { HttpMethod, joinPath } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';

/**
 * ### Удаление точки выдачи авто
 * basePath: /crm
 * controller: /maintenance
 * path: 'delivery-point'
 * method: DELETE
 * fullPath: /crm/maintenance/delivery-point
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmDeleteDeliveryPointEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Maintenance;
  export const endPointPath = 'delivery-point';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.DELETE;

  /**
   * Ответ
   */
  export type ResponseData = void;
  export type Response = null;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = null;

  /** Параметры запроса */
  export type RequestQueryParams = {
    id: string;
  };

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
