import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmVehicleGetResponseDto } from './dtos';
import { CrmControllersEnum } from '../crm.controller.enum';

/**
 * ### Получение информации по машине
 * basePath: /crm
 * controller: /vehicle
 * path:
 * method: GET
 * fullPath: /crm/vehicle/:vin
 */
export namespace CrmVehicleGetEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Vehicle;
  export const endPointPath = ':vin';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Информация о машине
   */
  export type ResponseData = CrmVehicleGetResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**Параметры пути:
   * * vin - vin номер авто
   */
  export interface RequestPathParams extends Record<string, string | number> {
    vin: string;
  }

  /** Параметры запроса: без параметров */
  export interface RequestQueryParams extends Record<string, string | number> {}

  /** Заголовки: без параметров */
  export interface RequestHeaders extends Record<string, string | number> {}

  /** Тело запроса: отсутствует */
  export type RequestBody = undefined;
}
