import { HttpMethod, joinPath, PaginationRequest, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { GetMonitoringResponseDto } from './dto';

/**
 * ### Получение мониторинга для автомобиля
 * basePath: /mobile-bff
 * controller: /vehicle
 * path: ':vin/monitoring'
 * method: GET
 * fullPath: /mobile-bff/vehicle/:vin/monitoring
 */
export namespace MobileBffGetMonitoringEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Vehicle;
  export const endPointPath = ':vin/monitoring';
  export const getUrl = (): string => joinPath([servicePath, controller, endPointPath], null, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: каталог авто
   */
  export type ResponseData = GetMonitoringResponseDto | null;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export interface RequestPathParams {
    vin: string;
  }

  /** Параметры запроса */
  export type RequestQueryParams = PaginationRequest & {
    startDate: Date;
    endDate: Date;
  };

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
