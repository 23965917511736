import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, concatMap, map, Observable, of, throwError, withLatestFrom } from 'rxjs';

import { AuthTokenState } from '../states/auth-token.state';
import { AuthTokenStateActions } from '../states/auth-token.state.actions';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  public intercept(originalReq: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return of(true).pipe(
      withLatestFrom(this.store.select(AuthTokenState.accessToken)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([_, token]: [boolean, string | null]) => this.addToken(originalReq, token)),
      concatMap((req) => next.handle(req)),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Conflict) {
            this.store.dispatch(new AuthTokenStateActions.Logout());
          }
        }

        return throwError(error);
      }),
    );
  }

  protected addToken(req: HttpRequest<unknown>, token: string | null): HttpRequest<unknown> {
    const alreadyHasToken = !!req.headers.get('authorization');

    return !token || alreadyHasToken ? req : req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
