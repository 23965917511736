import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';

/**
 * ### Добавление автомобиля в каталог
 * basePath: /crm
 * controller: /catalog
 * path: '/:vehicleId'
 * method: DELETE
 * fullPath: /crm/catalog/:vehicleId
 */

export namespace CrmDeleteCatalogEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Catalog;
  export const endPointPath = ':vehicleId';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.DELETE;

  /**
   * Ответ: отсутствует
   */
  export type ResponseData = {};
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = {
    vehicleId: string;
  };

  /**
   * Параметры запроса: отсутствуют
   */
  export type RequestQueryParams = Record<string, string | number>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};
}
