import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmPostCheckListTaskRequestDto } from './dtos';

/**
 * ### Добавление задачи
 * basePath: /crm
 * controller: /check-list
 * path: '/'
 * method: POST
 * fullPath: /crm/check-list/list/:listId/task
 */

export namespace CrmPostCheckListTaskEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.CheckList;
  export const endPointPath = 'list/:listId/task';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: отсутствует
   */
  export type ResponseData = {};
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути:
   * * id - id чеклиста
   * */
  export interface RequestPathParams extends Record<string, string | number> {
    listId: string;
  }

  /**
   * Параметры запроса: отсутствуют
   */
  export type RequestQueryParams = Record<string, string | number>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmPostCheckListTaskRequestDto;
}
