import { HttpMethod, joinPath } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';

/**
 * ### Отмена бронирования пользователя
 * basePath: /crm
 * controller: /rent
 * path: ':rentId/cancel-reservation'
 * method: POST
 * fullPath: /crm/rent/:rentId/cancel-reservation
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MobileBffCancelRentEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Rent;
  export const endPointPath = ':rentId/cancel-reservation';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ
   */
  export type ResponseData = void;
  export type Response = null;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {
    rentId?: string;
  };

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
