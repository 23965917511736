export enum ServersConfigTypes {
  DEV = 'dev',
  PROD = 'prod',
  TEST = 'test',
}

export const SERVERS_CONFIG: Record<ServersConfigTypes, { url: string }> = {
  dev: { url: '' },
  prod: { url: '' },
  test: { url: '' },
};
