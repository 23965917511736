import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmVehicleGetResponseDto, CrmVehiclePatchRequestDto } from './dtos';

/**
 * ### Обновление машины
 * basePath: /crm
 * controller: /vehicle
 * path: '/:vehicleId'
 * method: PATCH
 * fullPath: /crm/vehicle/:vin
 */

export namespace CrmVehiclePatchEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Vehicle;
  export const endPointPath = ':vin';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ: Пользователь
   */
  export type ResponseData = CrmVehicleGetResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**Параметры пути:
   * * vehicleId - vin номер авто
   */
  export interface RequestPathParams extends Record<string, string | number> {
    vin: string;
  }

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmVehiclePatchRequestDto;
}
