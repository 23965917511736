import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  MEDIA_BREAKPOINTS_PROVIDER,
  MediaBreakpointsProvider,
} from '@app/core/media-observer/media-breakpoints.provider';
import {
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  fromEvent,
  map,
  Observable,
  shareReplay,
  startWith,
  tap,
} from 'rxjs';

export
@Injectable({ providedIn: 'root' })
class MediaObserverService {
  public readonly width$: Observable<number>;

  public readonly breakpoint$: Observable<string>;

  constructor(
    @Inject(MEDIA_BREAKPOINTS_PROVIDER) private readonly mediaBreakpoints: MediaBreakpointsProvider,
    @Optional() @Inject(PLATFORM_ID) private readonly platformId: Platform | null,
  ) {
    this.width$ = this.buildWidthObservable();
    this.breakpoint$ = this.buildBreakpointObservable();
  }

  public isBreakpointDown(current: string, target: string, includeEquals = true): boolean {
    return includeEquals
      ? this.mediaBreakpoints[current] <= this.mediaBreakpoints[target]
      : this.mediaBreakpoints[current] < this.mediaBreakpoints[target];
  }

  public isBreakpointUp(current: string, target: string, includeEquals = true): boolean {
    return includeEquals
      ? this.mediaBreakpoints[current] >= this.mediaBreakpoints[target]
      : this.mediaBreakpoints[current] > this.mediaBreakpoints[target];
  }

  public isBreakpointEqual(current: string, target: string): boolean {
    return this.mediaBreakpoints[current] === this.mediaBreakpoints[target];
  }

  protected buildWidthObservable(): Observable<number> {
    if (this.platformId && isPlatformBrowser(this.platformId)) {
      return fromEvent(window, 'resize').pipe(
        startWith(window),
        map(() => window as Window),
        map((w) => w.innerWidth),
        shareReplay(),
      );
    } else {
      return EMPTY;
    }
  }

  protected buildBreakpointObservable(): Observable<string> {
    return this.width$.pipe(
      debounceTime(100),
      map((width) => this.matchWidthWithBreakpoint(width)),
      filter(Boolean),
      distinctUntilChanged(),
      shareReplay(),
    );
  }

  protected matchWidthWithBreakpoint(width: number): string | null {
    let lastBreakpoint = Object.keys(this.mediaBreakpoints)[0];

    if (!lastBreakpoint) {
      return null;
    }

    for (const breakpoint in this.mediaBreakpoints) {
      const maxWidth = this.mediaBreakpoints[breakpoint];

      if (width > maxWidth) {
        lastBreakpoint = breakpoint;
      } else {
        return lastBreakpoint;
      }
    }

    return lastBreakpoint;
  }
}
