import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, map, Observable, of, withLatestFrom } from 'rxjs';

import { LocaleEnum } from '../enums/locale.enum';
import { LocaleService } from './locale.service';

@Injectable({ providedIn: 'root' })
export class LocaleInterceptor implements HttpInterceptor {
  constructor(private readonly localeService: LocaleService) {}

  public intercept(originalReq: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return of(originalReq).pipe(
      withLatestFrom(this.localeService.locale$),
      map(([req, locale]) => this.addLocale(req, locale)),
      concatMap((req) => next.handle(req)),
    );
  }

  protected addLocale(req: HttpRequest<never>, locale: LocaleEnum | null): HttpRequest<never> {
    const alreadyHasLocale = !!req.headers.get('X-Lang');

    return !locale || alreadyHasLocale ? req : req.clone({ setHeaders: { 'X-Lang': locale } });
  }
}
