import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { MobileBffLoginRequestDto, MobileBffLoginResponseDto } from './dtos';

/**
 * ### Вход в МП
 * basePath: /mobile-bff
 * controller: /auth
 * path: '/login'
 * method: POST
 * fullPath: /mobile-bff/auth/login
 */
export namespace MobileBffLoginEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Auth;
  export const endPointPath = 'login';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: токен
   */
  export type ResponseData = MobileBffLoginResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: данные авторизации */
  export type RequestBody = MobileBffLoginRequestDto;

  export enum ErrorCodes {}
}
