export interface GetStatisticsResponseDto {
  /** Доступность статистики */
  availability: {
    /** Доступно или нет */
    available: boolean;
    /** Текст в случае недоступности */
    text: string | null;
  };
  /** Информация по стилю вождения */
  drivingStyleData: DrivingStyleData;
  /** Информация по пробегу */
  mileageData: MileageData;
  /** Информация по двигателю */
  engineData: EngineData;
  /** Информация для графика по среднему времени в пути по дням */
  driveTimeAvg: DriveTimeAverage;
  /** Информация по скорости max/avg по дням */
  driveTimeSpeed: DriveTimeSpeed;
}

interface DrivingStyleData {
  /** Количество баллов */
  score: number | null;
}

interface MileageData {
  /** Общий пробег */
  mileageTotal: number;
  /** Пробег в городе */
  mileageCity: number;
  /** Пробег на трассе */
  mileageTrack: number;
  mileageList?: {
    /** Дата в ISO */
    date: Date;
    /** Пробег в городе */
    mileageCity: number;
    /** Пробег на трассе */
    mileageTrack: number;
  }[];
}

interface EngineData {
  /** Общее время работы двигателя */
  runTimeTotal: number;
  /** Время работы двигателя в движении */
  runTimeMoving: number;
  /** Время работы двигателя без движения */
  runTimeNoMoving: number;
  runTimeList?: {
    /** Дата в ISO */
    date: Date;
    /** Общее время работы двигателя */
    runTimeTotal: number;
    /** Время работы двигателя в движении */
    runTimeMoving: number;
    /** Время работы двигателя без движения */
    runTimeNoMoving: number;
  }[];
}

interface SpeedData {
  /** Средняя скорость */
  speedAvg: number;
  /** Максимальная скорость */
  speedMax: number;
}

/** Информация по поездке за день */
interface TripTime {
  /** Дата поездки в ISO */
  date: Date;
  /** Время поездки в миллисекундах */
  duration: number;
  /** Количество поездок */
  tripCount: number;
}

interface DriveTimeAverage {
  /** Среднее время в пути за период */
  driveTimeAverage: number;
  /** Массив элементов времени в пути по дням */
  tripTimeList: TripTime[];
}

/** Информация по поездке за день */
interface TripSpeed {
  /** Дата поездки в ISO */
  date: Date;
  /** Средняя скорость */
  speedAvg: number;
  /** Максимальная скорость */
  speedMax: number;
}

interface DriveTimeSpeed {
  /** max/avg скорости за период */
  speed: SpeedData;
  /** Массив элементов max/avg скорости по дням */
  tripSpeedList: TripSpeed[];
}
