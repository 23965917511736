import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpMethod } from 'apps-dto';
import { Observable } from 'rxjs';

export interface OneAppDtoRequestPayload<ReqBody, ReqPath, ReqQuery, ReqHeaders> {
  body: ReqBody;
  path: ReqPath;
  query: ReqQuery;
  headers: ReqHeaders;
}

export interface OneAppDtoRequestOptions<ReqPath> {
  type: HttpMethod;
  getUrl?: (params: ReqPath) => string;
  url?: string;
}

export function buildHttpParams<T extends object>(obj?: T): HttpParams | undefined {
  let params = new HttpParams();

  if (obj === null || obj === undefined) {
    return;
  }

  if (typeof obj !== 'object') {
    return undefined;
  }

  Object.keys(obj).forEach((key: string) => {
    const value =
      typeof (<Record<string, unknown>>obj)[key] === 'string'
        ? ((<Record<string, unknown>>obj)[key] as string).trim()
        : (<Record<string, unknown>>obj)[key];

    if (typeof value !== 'undefined' && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((data) => (params = params.append(`${key}`, data)));
      } else {
        params = params.append(`${key}`, value as string);
      }
    }
  });

  return params;
}

export class AppsDtoRequest<ReqBody, ReqPath, ReqQuery, ReqHeaders, Res> {
  constructor(protected httpClient: HttpClient, protected options: OneAppDtoRequestOptions<ReqPath>) {}

  public execute(payload: OneAppDtoRequestPayload<ReqBody, ReqPath, ReqQuery, ReqHeaders>): Observable<Res> {
    const route =
      typeof this.options.getUrl === 'function' ? this.options.getUrl(payload.path) : this.options.url ?? '';

    return this.httpClient.request<Res>(this.options.type, route, {
      headers: payload.headers as never,
      params: buildHttpParams(payload.query as object | undefined),
      responseType: 'json',
      body: payload.body,
    });

    /*switch (this.options.type) {
      case 'POST':
      case 'PUT':
      case 'PATCH':
        return this.httpClient.request<Res>(this.options.type, route, {
          headers: payload.headers,
          params: buildHttpParams(payload.query),
          responseType: 'json',
          body: payload.body,
        });
      default:
        return this.httpClient.request<Res>(this.options.type, route, {
          headers: payload.headers,
          params: buildHttpParams(payload.query),
          responseType: 'json',
          body: payload.body,
        });
    }*/
  }
}
