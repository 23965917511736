import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { MobileBffLoginResponseDto, MobileBffRefreshRequestDto } from './dtos';

/**
 * ### Обновление токенов в МП
 * basePath: /mobile-bff
 * controller: /auth
 * path: '/refresh'
 * method: POST
 * fullPath: /mobile-bff/auth/refresh
 */
export namespace MobileBffRefreshEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Auth;
  export const endPointPath = 'refresh';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = false;
  export const type = HttpMethod.POST;

  /**
   * Ответ: токен
   */
  export type ResponseData = MobileBffLoginResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: токен */
  export type RequestBody = MobileBffRefreshRequestDto;

  export enum ErrorCodes {}
}
