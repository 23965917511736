import { HttpMethod, joinPath, PaginationInterface, PaginationRequest, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmUserDto } from './dtos';

/**
 * ### Получение списка пользователей CRM
 * basePath: /crm
 * controller: /user
 * path: '/'
 * method: GET
 * fullPath: /crm/user
 */

export namespace CrmUserFindEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.User;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Список пользователей
   */
  export type ResponseData = PaginationInterface<CrmUserDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса:
   * * onPage - Размер страницы
   * * page - Запрошенная страница
   * * search - Поиск по логину
   * */
  export interface RequestQueryParams
    extends Record<string, string | number | undefined | string[]>,
      PaginationRequest {
    search?: string;
  }

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};
}
