import { HttpMethod, joinPath, ResponseType } from '../../../../common';
import { AppServices } from '../../../app-services.enum';
import { MobileBffControllerEnum } from '../../mobile-bff.controller.enum';

/**
 * ### Отмена записи на ТО
 * basePath: /mobile-bff
 * controller: /vehicle-inspection
 * path: 'appointment/:appointmentId'
 * method: DELETE
 * fullPath: /mobile-bff/vehicle-inspection/appointment/:appointmentId
 */
export namespace MobileBffCancelInspectionAppointmentEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.VehicleInspection;
  export const endPointPath = 'appointment/:appointmentId';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.DELETE;

  /**
   * Ответ: бронирование
   */
  export type ResponseData = null;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = {
    appointmentId: string;
  };

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
