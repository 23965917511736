import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmGetCheckListStageResponseDto } from './dtos';

/**
 * ### Получение этапа чеклиста
 * basePath: /crm
 * controller: /check-list
 * path: '/'
 * method: GET
 * fullPath: /crm/check-list/stage/:id
 */

export namespace CrmGetCheckListStageEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.CheckList;
  export const endPointPath = 'stage/:id';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Этап
   */
  export type ResponseData = CrmGetCheckListStageResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути:
   * * id - id этапа
   * */
  export interface RequestPathParams extends Record<string, string | number> {
    id: string;
  }

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = {};

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};
}
