import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { LocaleEnum } from '../enums/locale.enum';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  locale$: Observable<LocaleEnum> = of(LocaleEnum.Ru);
}
