import { SuccessResponseType } from './response.interface';

export class SuccessResponse<T extends object | null = null> implements SuccessResponseType<T> {
  readonly meta: SuccessResponseType['meta'];

  readonly data: T;

  constructor(data: T) {
    this.data = data;
    this.meta = {
      success: true,
    };
  }
}

export function WrapSuccessResponse() {
  return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value as Function;
    descriptor.value = function (...args: unknown[]): Promise<SuccessResponseType> {
      const newMethod = method.apply(this, args);
      return Promise.resolve(newMethod).then((data) => new SuccessResponse(data));
    };
  };
}
