import { HttpMethod, joinPath, ResponseType } from '../../../../common';
import { AppServices } from '../../../app-services.enum';
import { CrmControllersEnum } from '../../crm.controller.enum';

/**
 * ### Удаление страховки
 * basePath: /crm
 * controller: /vehicle
 * path: ':vin/insurance/:insuranceId'
 * method: DELETE
 * fullPath: /crm/vehicle/:vin/insurance/:insuranceId
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmVehicleInsuranceDeleteEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Vehicle;
  export const endPointPath = ':vin/insurance/:insuranceId';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.DELETE;

  /**
   * Ответ
   */
  export type ResponseData = null;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {
    vin: string;
    insuranceId: string;
  };

  /** Параметры запроса: отсутствует */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
