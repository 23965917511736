import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmLoginRequestDto, CrmLoginResponseDto } from './dtos';

/**
 * ### Вход в CRM
 * basePath: /crm
 * controller: /auth
 * path: '/login'
 * method: POST
 * fullPath: /crm/auth/login
 */
export namespace CrmLoginEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Auth;
  export const endPointPath = 'login';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: токен
   */
  export type ResponseData = CrmLoginResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmLoginRequestDto;
}
