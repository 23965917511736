import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { MobileBffGetRentDriversResponse } from './dtos';

/**
 * ### Получение водителей аренды
 * basePath: /crm
 * controller: /rent
 * path: ':rentId/drivers'
 * method: GET
 * fullPath: /mobile-bff/rent/:rentId/drivers
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MobileBffGetRentDriversEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Rent;
  export const endPointPath = ':rentId/drivers';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = MobileBffGetRentDriversResponse;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {
    rentId: string;
  };

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
