import { HttpMethod, joinPath, ResponseType } from '../../../../common';
import { AppServices } from '../../../app-services.enum';
import { MobileBffControllerEnum } from '../../mobile-bff.controller.enum';

import { MobileBffGetRentInspectionAppointmentResponse } from './interfaces';

/**
 * ### Получение записей на ТО по аренде
 * basePath: /mobile-bff
 * controller: /vehicle-inspection
 * path: 'appointment'
 * method: GET
 * fullPath: /mobile-bff/vehicle-inspection/appointment
 */
export namespace MobileBffGetRentInspectionAppointmentsEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.VehicleInspection;
  export const endPointPath = 'appointment';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: бронирование
   */
  export type ResponseData = MobileBffGetRentInspectionAppointmentResponse;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = {
    rentId: string;
  };

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
