/** общий тип ответа */
export type ResponseType<
  ResponseData extends object | null = null,
  ResponseMetadata extends ResponseMeta = ResponseMeta,
> = ResponseInterface<ResponseData, ResponseMetadata>;

/** общий тип успешного ответа */
export type SuccessResponseType<
  ResponseData extends object | null = null,
  ResponseMetadata extends SuccessResponseMeta = SuccessResponseMeta,
> = ResponseInterface<ResponseData, ResponseMetadata>;

/** общий тип неудачного ответа */
export type ErrorResponseType<
  ResponseData extends object | null = null,
  ResponseMetadata extends ErrorResponseMeta = ErrorResponseMeta,
> = ResponseInterface<ResponseData, ResponseMetadata>;

/** Интерфейс ответа сервера */
interface ResponseInterface<ResponseData extends object | null = null, ResponseMetadata = ResponseMeta> {
  /** Метаданные ответа */
  meta: ResponseMetadata;
  /** Данные ответа */
  data: ResponseData;
}

export interface BaseResponseMetadata {
  /** Флаг успешности завершения запроса */
  success: boolean;
}

export type ResponseMeta = SuccessResponseMeta | ErrorResponseMeta;

export interface SuccessResponseMeta extends BaseResponseMetadata {
  /** Флаг успешности завершения запроса, явно true для вывода типа */
  success: true;
}

export interface ErrorResponseMeta extends BaseResponseMetadata {
  success: false;
  /** Текстовый код ошибки, например `system.InternalException` */
  code: string;
  /** Поясняющее сообщение, сопровождающее ошибку */
  message: string;
  /** HTTP статус код ошибки */
  status: number;
  /** requestId запроса */
  requestId: string;
  /** Вспомогательное сообщение, более точно описывающее проблему */
  description?: string;
  /** Стектрейс для подробного изучения проблемы */
  stack?: object[];
}

export interface ValidationErrorResponse extends ErrorResponseMeta {
  /** Детализация ошибки валидации. Представляет собой объект, в котором
   * * ключ - свойство, не прошедшее валидацию
   * * значение - массив сообщений о не прошедших проверках
   */
  details: {
    [property: string]: string[];
  };
}
