export interface MobileBffSearchDriverRequest {
  /** Идентификатор водителя (пользователя) */
  driverId: string;
  /** Номер телефона */
  phone: string;
  /** Имя */
  firstName: string | null;
  /** Фамилия (первая буква) */
  lastName: string | null;
}
