import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { MobileBffSetEmailRequestDto } from './dtos';

/**
 * ### Вход в МП
 * basePath: /mobile-bff
 * controller: /customer
 * path: '/set-email'
 * method: PATCH
 * fullPath: /mobile-bff/customer/set-email
 */
export namespace MobileBffSetEmailEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Customer;
  export const endPointPath = 'set-email';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = false;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ: отсутствует
   */
  export type ResponseData = {};
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: данные пользователя */
  export type RequestBody = MobileBffSetEmailRequestDto;

  export enum ErrorCodes {}
}
