import { HttpMethod, joinPath } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';

/**
 * ### Обновление языка для девайса
 * basePath: /mobile-bff
 * controller: /auth
 * path: '/update-device-language'
 * method: POST
 * fullPath: /mobile-bff/auth/update-device-language
 */
export namespace MobileBffUpdateDeviceLanguageEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Auth;
  export const endPointPath = 'update-device-language';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки */
  export type RequestHeaders = {
    'x-language': string;
    'x-device-id': string;
  };

  /** Тело запроса: данные пользователя */
  export type RequestBody = Record<string, string | number>;

  export enum ErrorCodes {}
}
