import {
  ErrorResponseType,
  ResponseMeta,
  ResponseType,
  SuccessResponseType,
  ValidationErrorResponse,
} from './response.interface';

/** Метод проверки ответа сервера на ошибку */
export function isErrorResponse<T extends object | null>(response: ResponseType<T>): response is ErrorResponseType<T> {
  return response.meta.success === false;
}

/** Метод проверки ответа сервера на успех */
export function isSuccessResponse<T extends object | null>(
  response: ResponseType<T>,
): response is SuccessResponseType<T> {
  return response.meta.success === true;
}

/** Метод проверки ответа сервера на ошибку валидации */
export function isValidationError(responseMeta: ResponseMeta): responseMeta is ValidationErrorResponse {
  return responseMeta.success === false && responseMeta.status === 422;
}
