import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { MobileBffGetCatalogResponseDto } from './dtos';

/**
 * ### Получение каталога автомобилей для уведомлений
 * basePath: /mobile-bff
 * controller: /catalog
 * path: '/notification'
 * method: GET
 * fullPath: /mobile-bff/catalog/notification
 */
export namespace MobileBffGetCatalogNotificationEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Catalog;
  export const endPointPath = 'notification';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = false;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Каталог доступных автомобилей
   */
  export type ResponseData = PaginationInterface<MobileBffGetCatalogResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
