import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmDeviceGetResponseDto } from './dtos';

/**
 * ### Получения устройства по id
 * basePath: /crm
 * controller: /device
 * path: /:id
 * method: GET
 * fullPath: /crm/device/:id
 */
export namespace CrmDeviceGetEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Device;
  export const endPointPath = ':id';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Список устройств
   */
  export type ResponseData = CrmDeviceGetResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути:
   * * id - ID устройства
   * */
  export interface RequestPathParams extends Record<string, string | number> {
    id: string;
  }

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: без параметров */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = undefined;
}
