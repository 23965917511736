export const joinPath = (
  pathItems: string[],
  values: Record<string, string | number> | null,
  full = false,
  separator = '/',
): string => {
  const paramRegExp = /^:.*$/gi;
  const pathParamValueRegExp = /^([A-Za-z0-9-._~])+$/;
  const setParams = (param: string, values: Record<string, string | number>): string => {
    const value = values[param]?.toString();

    if (typeof value !== 'string' || !pathParamValueRegExp.test(value)) {
      throw new Error(`path param must be string and match regex ${pathParamValueRegExp.toString()}`);
    }

    return value;
  };

  const separatedPath = [...pathItems.map((pathItem) => pathItem.split('/'))]
    .reduce((pre, crr) => [...pre, ...crr], [])
    .map((paramItem) => {
      if (paramRegExp.test(paramItem)) {
        return setParams(paramItem.replace(':', ''), values || {});
      }

      return paramItem;
    });
  return (
    (full ? '/' : '') + separatedPath.filter((pathItem) => !!pathItem).join(separator)
    // .replace(/(\/{2,10})/gi, '/')
    // .replace(/(^\/)|(\/$)/gi, '')
  );
};
