export interface CrmUserPostRequestDto {
  active: boolean;
  login: string;
  password: string;
  roleCode: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  patronymic: string | null;
}
