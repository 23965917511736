import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import * as stream from 'stream';

/**
 * ### Обновление токена уведомлений устройства пользователя МП
 * basePath: /mobile-bff
 * controller: /customer
 * path: '/update-push-token'
 * method: PATCH
 * fullPath: /mobile-bff/customer/update-push-token
 */
export namespace MobileBffUpdateCustomerPushTokenEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Customer;
  export const endPointPath = 'update-push-token';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ: отсутствует
   */
  export type ResponseData = {};
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export interface RequestBody {
    pushToken: stream;
  }

  export enum ErrorCodes {}
}
