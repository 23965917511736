import { Injectable } from '@angular/core';
import { AuthApiService } from '@app/core/api/auth-api.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, forkJoin, Observable, of, tap } from 'rxjs';

import { ProfileModel } from '../models/profile.model';
import { AuthProfileStateActions } from './auth-profile.state.actions';
import { AuthTokenState, AuthTokenStateModel } from './auth-token.state';

interface AuthProfileStateModel {
  profile: ProfileModel | null;
  hostProfile: ProfileModel | null;
}

const EMPTY_STATE: AuthProfileStateModel = {
  profile: null,
  hostProfile: null,
};

@State<AuthProfileStateModel>({
  name: 'profile',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class AuthProfileState {
  constructor(private readonly authApiService: AuthApiService, private readonly store: Store) {}

  @Selector()
  public static profile(state: AuthProfileStateModel): ProfileModel | null {
    return state.profile;
  }

  @Selector([AuthProfileState.profile])
  public static userId(_: AuthProfileStateModel, profile: ProfileModel | null): string | null {
    return profile?.id || null;
  }

  @Selector()
  public static hostProfile(state: AuthProfileStateModel): ProfileModel | null {
    return state.hostProfile;
  }

  @Action(AuthProfileStateActions.Load, { cancelUncompleted: true })
  public loadProfile({ patchState }: StateContext<AuthProfileStateModel>): Observable<unknown> {
    const tokenState = this.store.selectSnapshot<AuthTokenStateModel>(AuthTokenState);

    const operations$: Observable<ProfileModel | null>[] = [
      tokenState.accessToken ? this.authApiService.getMe() : of(null),
      tokenState.hostAccessToken ? this.authApiService.getMe(tokenState.hostAccessToken) : of(null),
    ];

    return forkJoin(operations$).pipe(
      catchError(() => of([null, null])),
      tap(([profile, hostProfile]) =>
        patchState({
          profile,
          hostProfile,
        }),
      ),
    );
  }
}
