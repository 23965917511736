import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmPatchTariffRequestDto, CrmPatchTariffResponseDto } from './dtos';

/**
 * ### Обновление информации тарифа
 * basePath: /crm
 * controller: /tariff
 * path: ':tariffId'
 * method: PATCH
 * fullPath: /crm/tariff/:tariffId
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmPatchTariffEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Tariff;
  export const endPointPath = ':tariffId';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ
   */
  export type ResponseData = CrmPatchTariffResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {
    tariffId: string;
  };

  /** Параметры запроса: отсутствует */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmPatchTariffRequestDto;

  export enum ErrorCodes {}
}
