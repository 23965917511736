import { HttpMethod, joinPath } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';

/**
 * ### Удаление водителя аренды (завершение досупа)
 * basePath: /crm
 * controller: /rent
 * path: ':rentId/driver/:driverId'
 * method: DELETE
 * fullPath: /crm/rent/:rentId/driver/:driverId
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmDeleteRentDriverEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Rent;
  export const endPointPath = ':rentId/delete-driver/:driverId';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.DELETE;

  /**
   * Ответ
   */
  export type ResponseData = void;
  export type Response = null;

  /** Параметры пути */
  export type RequestPathParams = {
    /** Идентификатор аренды */
    rentId: string;
    /** Идентификатор водителя */
    driverId: string;
  };

  /** Параметры запроса */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
