import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { PushHistoryModel } from './models';

/**
 * ### Просмотр уведомления
 * basePath: /mobile-bff
 * controller: /notification
 * path: 'read'
 * method: POST
 * fullPath: /mobile-bff/notification/read
 */
export namespace MobileBffReadNotificationsEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Notification;
  export const endPointPath = 'read';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: не законченная аренда
   */
  export type ResponseData = PaginationInterface<PushHistoryModel>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;
  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {
    notificationIds: string[] | null;
  };

  export enum ErrorCodes {}
}
