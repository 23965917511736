import { HttpMethod, joinPath, PaginationInterface, PaginationRequest, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { MobileBffGetCatalogResponseDto } from './dtos';

/**
 * ### Получение каталога доступных автомобилей
 * basePath: /mobile-bff
 * controller: /catalog
 * path: '/'
 * method: GET
 * fullPath: /mobile-bff/catalog/
 */
export namespace MobileBffGetCatalogAvailableEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Catalog;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = false;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Каталог доступных автомобилей
   */
  export type ResponseData = PaginationInterface<MobileBffGetCatalogResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: пагинация */
  export type RequestQueryParams = PaginationRequest;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
